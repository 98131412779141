'use client'

import type { Route } from 'next'
import { useRouter } from 'next/navigation'
import type React from 'react'
import styles from './CommonMenu.module.scss'
import FaIcon, {
  faArrowRightFromBracket,
  faChevronRight,
} from '@/components/icon/FaIcon/FaIcon'
import { useIsMenuOpenedAtom } from '@/state/global'
import type { MenuLinks } from '@/types'

function CommonMenu({
  iconUrl,
  name,
  level,
  birthdayText,
  isArtist = false,
}: {
  iconUrl?: string
  name?: string
  level?: number
  birthdayText?: string
  isArtist?: boolean
}) {
  const [isMenuOpenedValue, setIsMenuOpened] = useIsMenuOpenedAtom()
  const router = useRouter()
  const logout = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    location.href = `${process.env.NEXT_PUBLIC_API_BASE_URL}/auth/v1/web/logout`
  }

  const transit = (href: Route, e: React.MouseEvent) => {
    e.preventDefault()
    setIsMenuOpened(false)
    router.push(href)
  }

  const links: MenuLinks = [
    { text: 'ポイント確認・購入', href: '/purchase' },
    { text: 'SNS連携', href: '/sns' },
    { text: '登録済みクレジットカード', href: '/purchase/credit-card' },
    { text: 'アカウント連携', href: '/migrate-account' },
  ]

  const artistLinks: MenuLinks = [
    { text: 'ポイント確認・購入', href: '/purchase' },
    { text: 'SNS連携', href: '/sns' },
    { text: 'コインの確認・交換', href: '/own-coin' },
    { text: '口座/住所情報の確認・変更', href: '/bank-address' },
    { text: 'メールアドレスの確認・変更', href: '/email' },
    { text: '登録済みクレジットカード', href: '/purchase/credit-card' },
    { text: 'アカウント連携', href: '/migrate-account' },
  ]

  const showLinks = isArtist ? artistLinks : links

  return (
    <>
      {isMenuOpenedValue && (
        <div className={`${styles.componentStyle}`}>
          <div className={styles.container}>
            <button
              type='button'
              className={styles.modalClose}
              onClick={() => setIsMenuOpened(false)}
            />
            <div className={styles.iconLevelContainer}>
              {iconUrl && (
                <img src={iconUrl} alt={name} className={styles.icon} />
              )}
              {level && (
                <div className={styles.levelContainer}>Level {level}</div>
              )}
            </div>
            {name && <p className={styles.name}>{name}</p>}
            {birthdayText && <p className={styles.birthday}>{birthdayText}</p>}
            <div className={styles.linkContainer}>
              {showLinks.map((item, index) => (
                // biome-ignore lint/a11y/useValidAnchor: <explanation>
                <a
                  href='#'
                  className={styles.link}
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  key={index}
                  onClick={(e) => transit(item.href, e)}
                >
                  {item.text}
                  <FaIcon icon={faChevronRight} />
                </a>
              ))}
            </div>
            <button
              onClick={logout}
              className={styles.logoutButton}
              type='button'
            >
              <FaIcon icon={faArrowRightFromBracket} />
              ログアウト
            </button>
          </div>
        </div>
      )}
    </>
  )
}
export default CommonMenu
