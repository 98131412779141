'use client'

import Link from 'next/link'
import styles from './CommonHeader.module.scss'
import FaIcon, {
  faArrowRightFromBracket,
  faArrowRightToBracket,
} from '@/components/icon/FaIcon/FaIcon'
import { PRODUCT_NAME } from '@/constants'
import { useIsMenuOpenedAtom } from '@/state/global'
import utilsStyles from '@/styles/modules/utils.module.scss'

function CommonHeader({ isLoggedIn = false }: { isLoggedIn?: boolean }) {
  const [_, setIsMenuOpened] = useIsMenuOpenedAtom()
  const openMenu = (e: React.MouseEvent) => {
    e.preventDefault()
    setIsMenuOpened(true)
  }
  return (
    <header className={styles.componentStyle}>
      <div className={styles.container}>
        <Link href='/' className={styles.logo}>
          <img src='/cz-logo.png' alt={PRODUCT_NAME} />
        </Link>
        {isLoggedIn ? (
          <div>
            {/* PCのみ */}
            <div className={`${styles.auth} ${utilsStyles.onlyPc}`}>
              <FaIcon icon={faArrowRightFromBracket} />
              <a
                href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/auth/v1/web/logout`}
                className={styles.authLink}
              >
                ログアウト
              </a>
            </div>
            {/* SPのみ */}
            {/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
            <a
              className={`${styles.openMenuIcon} ${utilsStyles.onlySp}`}
              href='#'
              onClick={(e) => openMenu(e)}
            >
              <span />
              <span />
              <span />
            </a>
          </div>
        ) : (
          <div className={styles.auth}>
            <FaIcon icon={faArrowRightToBracket} />
            <Link href='/auth/signin' className={styles.authLink}>
              ログイン
            </Link>
          </div>
        )}
      </div>
    </header>
  )
}

export default CommonHeader
