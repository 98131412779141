'use client'

import type {
  IconDefinition,
  SizeProp,
} from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export {
  faChevronRight,
  faClock,
  faSearch,
  faChevronUp,
  faChevronLeft,
  faChevronDown,
  faExclamationCircle,
  faSquareArrowUpRight,
  faHeart,
  faArrowRightToBracket,
  faArrowRightFromBracket,
} from '@fortawesome/free-solid-svg-icons'

type Props = {
  icon: IconDefinition
  size?: SizeProp
}

function FaIcon({ icon, size }: Props) {
  return <FontAwesomeIcon icon={icon} size={size} />
}

export default FaIcon
